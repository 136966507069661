
$(document).on('turbolinks:load', function() {
	// only load this for payment pages
	// if($("#payment_method").length > 0 || $("#cart_items").length > 0)
		donationsPageLoad();
});

function donationsPageLoad() {
  console.log("Load donations - javascript/spurimpact/donations.js")

	// we should not do this till we know the payment methods available
	var stripe = Stripe(window.stripeKey);
	window.clearFields = clearFields;
	checkRegOptions();
	checkDynamicRegOptions();

	function checkDependencies(target, show) {
		var t = target;
		if (!t.hasClass('form_field')) {
			t = t.parents('.form_field');
		}

		if (t !== undefined && show !== undefined) {
			var $dependent;
			var $antiDependent;

			if ( t.data('dependent-id') ) {
				$dependent = $('#' + t.data('dependent-id'));
				$antiDependent = $('#not-' + t.data('dependent-id'));
			} else {
				$dependent = $('.' + t.data('dependent-class'));
				$antiDependent = $('.not-' + t.data('dependent-class'));
			}

			if (show == true) {
				$dependent.slideDown('fast');
				$antiDependent.slideUp('fast');
				clearFields($antiDependent);

				$('input.wasRequired').each(function() {
					$(this).attr('required', 'required');
					$(this).removeClass('wasRequired');
				})
			} else {
				$dependent.slideUp('fast');
				clearFields($dependent);
				$antiDependent.slideDown('fast');

				$dependent.find('input').each(function(){
					if ( $(this).attr('required') == 'required' ) {
						$(this).addClass('wasRequired');
						$(this).removeAttr('required');
					}
				});
			}
			return $dependent;
		}
		return undefined;
	}

	function clearFields(target) {
		$(':input',target)
		  .not(':button, :submit, :reset, :hidden')
		  .val('')
		  .prop('checked', false)
		  .prop('selected', false);
	}

	function checkRegOptions() {
		var $activity = $('#options_activity').find('input:checked');
		var $division = $('#options_division').find('input:checked');
		var $numRegistrantsInput = $("input[name=num_registrants]:checked");

		if ($activity.length == 1) {
			var isTriathlon =  $activity.attr('id').indexOf('tri') >= 0;
			if (isTriathlon) {
				$('#add_registrants').slideDown('fast');
			} else {
				$('#add_registrants').slideUp('fast');
				$('#add_registrants_details').slideUp('fast');
				$('#num_registrants_1').prop('checked', true);
			}
		}

		if ($numRegistrantsInput.val()) {
			var numRegistrants = parseInt($numRegistrantsInput.val());
			if (numRegistrants > 1) {
				$('#add_registrants_details').slideDown('fast');
			} else {
				$('#add_registrants_details').slideUp('fast');
			}
		}

		if ($activity.length == 1 && $division.length == 1) {
			// Update cost when both are filled
			var cost = $activity.data('cost');
			var description = $activity.data('description') + ': ' + $division.data('description') + ' division';

			if ($numRegistrantsInput.val()) {
				var numRegistrants = parseInt($numRegistrantsInput.val());
				cost = cost * numRegistrants;
				if (numRegistrants > 1) {
					var additionalDesc = " + " + $numRegistrantsInput.siblings("label").text();
					description += additionalDesc;
				}
			}

			$('.cost_confirm').slideDown('fast');
			$('.cost_confirm_amount').text(formatDollars(cost));
			$('#registration_amount').val(cost);

			$('.cost_confirm_description').text(description);
			$('#registration_description').val(description);
		} else {
			$('.cost_confirm').slideUp('fast');
			$('#registration_amount').val('');
			$('#registration_description').val('');
			$('#options_teammates').slideUp('fast');
		}
	}

	function checkDynamicRegOptions() {
		var total = 0.0;
		var description = [];
		var htmlDescription = "";
		var anyFree = false;

		let costfields = $('.registration_cost_field');
		for(let i=0; i<costfields.length; i++){
			let costfield = costfields[i];

			let data = {};
			let cost = 0.0;
			let desc = "";
			let free = false;

			if ($(costfield).hasClass("checkbox") && $(costfield).prop("checked")){
				data = $(costfield).data();

			} else  if ($(costfield).hasClass("select")){
				data = $(costfield).find("option:selected").data();

			} else if ($(costfield).hasClass("radio")){
				// We need to define this to get the selected option based on the radio name
				// var selectedOption = $('input[name="choose_donation"]:checked');
			}

			if(Object.keys(data).length > 0){
				cost =  parseFloat(data.cost);
				desc = data.description;
				free = data.free;

				if(free)
					anyFree = true;

				total += cost;
				htmlDescription += `•  ${desc}<br/>`;
				description.push(desc);
			}
		}

		if (total > 0 || anyFree) {
			$('.cost_confirm').slideDown('fast');
			$('.cost_confirm_amount').text(formatDollars(total));
			$('#registration_amount').val(total);
			$('.cost_confirm_description').html(htmlDescription);
			$('#registration_description').val(description.join("; "));
		} else {
			$('.cost_confirm').slideUp('fast');
			$('#registration_amount').val('');
			$('#registration_description').val('');
		}

		displayTotals();
	}

	function getDependencies(target) {
		var $target = $(target);
		var show;

		if ($target.prop('checked')) {
			show = $target.data('dependent-show');
		}
		if ($target.attr("type") == "checkbox") {
			show = $target.prop("checked");
		}

		checkDependencies($target, show);
	}

	function formatDollars(number) {
		return number.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
	}

	function currentSubtotal() {
		var registration_amount = parseInt($('#registration_amount').val()) || 0;
		var donation_amount = parseFloat($('#amount_input').val()) || 0;
		var subtotal = registration_amount + donation_amount;
		return subtotal;
	}

	function calculateDiscount() {
		var registration_amount = parseInt($('#registration_amount').val()) || 0;
		var discountPercent = parseFloat($('#coupon_discount_percent').val()) || 0;
		var discountDollars = parseFloat($('#coupon_discount_dollars').val()) || 0;

		if (registration_amount == 0) {
			return 0;
		}

		if (discountPercent > 0) {
			var percentage = discountPercent / 100;
			return (registration_amount * percentage);
		} else if (discountDollars > 0) {
			return discountDollars;
		} else {
			return 0;
		}
	}

	function calculateDonationDiscount() {
		if (window.enable_donation_threshold_discount) {
			var donationAmount = parseFloat($('#amount_input').val()) || 0;
			var registrationAmount = parseInt($('#registration_amount').val()) || 0;

			var thresholdDollars = parseFloat(window.donation_threshold_amount_dollars);
			var discountDollars = parseFloat(window.donation_threshold_discount_dollars);
			var discountPercent = parseFloat(window.donation_threshold_discount_percent);
			var minRegistrationDollars = parseFloat(window.donation_threshold_minimum_registration_dollars);

			if (donationAmount >= thresholdDollars && registrationAmount >= minRegistrationDollars) {
				var reduction = 0;
				if (discountDollars > 0) {
					reduction += discountDollars;
				}
				if (discountPercent > 0) {
					reduction += (registrationAmount * (discountPercent / 100.0));
				}
				return reduction;
			}
		}

		return 0;
	}

	function displayTotals() {
		var registration_amount = parseInt($('#registration_amount').val()) || 0;
		var donation_amount = parseFloat($('#amount_input').val()) || 0;

		var showFee = true;
		if ( $("#cover_fee").length == 1 && !$("#cover_fee").prop("checked") ) {
			showFee = false;
			$('#transaction_fee').hide();
		}

		var discount = calculateDiscount();
		registration_amount -= discount;

		var autoDonationDiscount = calculateDonationDiscount();
		registration_amount -= autoDonationDiscount;
		// console.log(autoDonationDiscount);

		if (registration_amount < 0) {
			registration_amount = 0;
		}

		var subtotal = registration_amount + donation_amount;

		var fee = 0;
		if (showFee && subtotal > 0){
			fee = (subtotal * (window.transaction_percent_fee / 100)) + window.transaction_fixed_fee;
		}

		var total = subtotal + fee;

		if (registration_amount > 0 || discount > 0) {
			var regText = "Registration cost: " + formatDollars(registration_amount);

			if (discount > 0) {
				regText += " (" + formatDollars(discount) + " coupon applied)";
			}
			if (autoDonationDiscount > 0) {
				regText += " (" + formatDollars(autoDonationDiscount) + " discount applied)";
			}

			$('#transaction_registration').text(regText).show();

		} else {
			$('#transaction_registration').hide();
		}


		if (donation_amount > 0) {
			$('#transaction_donation').text( "Donation amount: " + formatDollars(donation_amount) ).show();
		} else {
			$('#transaction_donation').hide();
		}

		if (subtotal > 0) {
			if (showFee) {
				var feeText = "Processing fee: ";
				$('#transaction_fee').text( feeText + formatDollars(fee) ).show();
			}
			$('#transaction_total').text( formatDollars(total) );
		} else {
			$('#transaction_fee').hide();
			$('#transaction_total').text( formatDollars(0) );
		}
		return subtotal;
	}

	function changeAmount(amountVal) {
		if (amountVal == "other") {
			$('#amount_input').val("");
			$('#custom_donation_amount').slideDown('fast');
		} else {
			$('#amount_input').val( amountVal );
			$('#custom_donation_amount').slideUp('fast');
		}

		displayTotals();
	}

	function applyCoupon() {
		var code = $('#coupon_code').val();
		var eventID = $('#event_id').val();
		var couponURL = "/check_coupon/" + eventID + "/" + code;

		$('#coupon_discount_percent').val(0);
		$('#coupon_discount_dollars').val(0);
		$('#coupon_notice').slideUp();

		var subtotal = currentSubtotal();
		if (subtotal == 0) {
			$('#coupon_notice_text').text("Please choose your transaction options before applying a coupon.");
			$('#coupon_notice').slideDown();
		} else {
			$.getJSON(couponURL, function(couponResponse) {
				if (couponResponse.error_text != undefined) {
					$('#coupon_notice_text').text(couponResponse.error_text);
					$('#coupon_notice').slideDown();
				} else {
					$('#coupon_discount_percent').val( couponResponse.discount_percent );
					$('#coupon_discount_dollars').val( couponResponse.discount_dollars );

					$('#coupon_notice_text').text(couponResponse.description);
					$('#coupon_notice').slideDown();
				}
			}).always(function() {
				displayTotals();
			})
		}
	}

	if ($('.form')) {
		// Initial check
		$('.form :input').each(function() {
			var $target = $(this);
			getDependencies($target);
		});
	}

	$('.form').on("change", function(e){
		var $target = $(e.target);
		getDependencies($target);
	});

	$('#choose_team').on("change", function(e) {
		if ( $(this).val() == "new") {
			$('#options_new_team').slideDown('fast');
		} else {
			$('#options_new_team').slideUp('fast');
			clearFields($('#options_new_team'));
		}
	});

	$('#options_activity').on("change", function(e) {
		var id = $(e.target).data('activity-id');
		var $divisions = $('#options_division .form_field_radio');
		$.each($divisions, function(index, value) {
			var $divisionField = $(value);
			var validFor = $divisionField.data('valid-for');
			if ( validFor.indexOf(id) == -1 ) {
				$divisionField.slideUp('fast');
				clearFields($divisionField);
			} else {
				$divisionField.slideDown('fast');
			}
		});
	});

	$('.select_any_option_once select').on("change", function(e) {
		var $target = $(e.target);
		var $parent = $target.closest('.select_any_option_once');

		$parent.find('option').each(function() {
			$(this).prop('disabled', false);
		});

		$parent.find('select').each(function() {
			var $thisSelect = $(this);
			var $otherSelects = $target.closest('.select_any_option_once').find('select').not($thisSelect);
			var selected = $thisSelect.val();

			var $disableOptions = $otherSelects.find('option').filter(function(i) {
				return $(this).val() == selected;
			});
			$disableOptions.each(function() {
				$(this).prop('disabled', true);
			});
		});
	});

	$('#user_address_country').on("change", function(e) {
		if ( $(this).val() != "US" ) {
			$('#user_address_state').prop('required', false);
			$('.address_state_field').hide();
	   } else {
			$('#user_address_state').prop('required', true);
		   $('.address_state_field').show();
	   }
   });

	// Registration options

	$('#choose_nonprofit').on('change', function() {
		var id = $(this).val();
		var $choose_team = $('#choose_team');
		$choose_team.val("");

		if (id != "") {
			$.getJSON("/list_fkids/" + id, function(childFundraisers) {
				console.log(childFundraisers);
				if (childFundraisers.length > 0) {
					// There are child fundraisers for the selected nonprofit

					$choose_team.children().each(function() {
						console.log($(this).val());

						if (childFundraisers.indexOf( parseInt($(this).val()) ) == -1) {
							$(this).prop("disabled", true);
							$(this).prop("hidden", true);
						} else {
							$(this).prop("disabled", false);
							$(this).prop("hidden", false);
						}
					});
					$choose_team.show();

				} else {
					$choose_team.children().each(function() {
						$(this).prop("disabled", true);
						$(this).prop("hidden", true);
					});
					$choose_team.hide();
				}
			});
		} else {
			$choose_team.children().each(function() {
				$(this).prop("disabled", false);
				$(this).prop("hidden", false);
			});
			$choose_team.show();
		}
	});

	$('#choose_nonprofit, #choose_donation_fundraiser').on('change', function() {
		var id = $(this).val();
		if (id != "") {
			var name = $(this).find('option:selected').text();
			$('#donation_fundraiser_id').val(id);
			$('#amount_input_label').text("Make a donation to " + name + " (optional)");
			$('#donation_options_section').slideDown();
		} else {
			$('#donation_fundraiser_id').val("");
			$('#donation_options_section').slideUp();
		}
	});

	$('input[name="support_nonprofit"]').on('change', function() {
		if ( $(this).val()  == "yes" ) {
			$('#options_choose_donation_section').slideUp();
		} else {
			$('#options_choose_donation_section').slideDown();
		}
	});

	$('input[name="support_nonprofit"], input[name="choose_donation"]').on('change', function() {
		if ( $(this).val()  == "yes" ) {
			// $('#donation_options_section').slideDown();
		} else {
			$('#donation_options_section').slideUp();
			$('#donation_fundraiser_id').val("");
		}
	})
	$('.reg_options').on('change', function(e) {
		checkRegOptions();
	});

	$('.registration_field').on('blur keydown input change',function() {
		checkDynamicRegOptions();
	});

	$('.registration_cost_field').on('change', function() {
		checkDynamicRegOptions();
	});

	$('.clearable_field').on('change', function() {
		if ( $(this).find('input:checked').length > 0 ) {
			$(this).find('.clear_fields').fadeIn('fast');
		} else {
			$(this).find('.clear_fields').fadeOut('fast');
		}
	});

	$('.clear_fields').on("click", function() {
		clearFields( $(this).parents('.clearable_field') );
		$(this).parents('.clearable_field').change();
		$(this).parents('.clearable_field').find('input').change();
	});

	var possibleRegFieldIDs = {
		'#registration_First_name': '#user_first_name',
		'#registration_Last_name': '#user_last_name',
		'#registration_Email': '#user_email',
		'#registration_Phone': '#user_phone',
		'#registration_Address': '#user_address1',
		'#registration_City': '#user_address_city',
		'#registration_State': '#user_address_state',
		'#registration_ZIP': '#user_address_zip',
		'#registration_country': '#user_address_country'
	}

	if ( $(Object.keys(possibleRegFieldIDs).join(', ')).length > 0 ) {
		$('#copy_from_reg').show();
	}

	$('#copy_from_reg').on('click', function() {
		$.each(possibleRegFieldIDs, function(originalID, copyID) {
			var val = $(originalID).val();

			if(val != undefined)
				$(copyID).val(val);
		});
	});

	// Donation buttons

	if ($('input[name=amount_btn]:checked').val()) {
		changeAmount( $('input[name=amount_btn]:checked').val() );
	}

	$('input[name=amount_btn]').on("change", function() {
		var amountVal = $(this).val();
		changeAmount(amountVal);
	});


	// Display transaction totals

	if ( $('#registration_amount').val() || $('#amount_input').val() ) {
		displayTotals();
	}

	$('#registration_amount, #amount_input, #cover_fee').on('blur keydown input change',function() {
		displayTotals();
	});

	// Apply coupons

	$('#apply_coupon').on("click", function(e) {
		e.preventDefault();
		applyCoupon();
		return false;
	});

	$('#coupon_code').on("keydown", function(e) {
		if (e.which == 13) {
			// return
			e.preventDefault();
			applyCoupon();
			return false;
		}
	});

	$('#parent_fundraiser_id').on("change", function() {
		$('#donation_fundraiser_id').val( $(this).val() );
	});

	// Check matches
	$('#donation_company_id').on("change", function() {
		if ( $(this).val() != '' ) {
			$('#company_match_loading').slideDown(100);
			var eventID = $('#event_id').val();
			var couponURL = "/check_company_match";
			$.getJSON(couponURL, {
				event_id: eventID,
				company_id: $(this).val(),
				fundraiser_id: $('#donation_fundraiser_id').val()
			}).done(function(data) {
				if (data && data.results && data.results == "Match") {
					$('#has_company_match').slideDown();
					$('#no_company_match').slideUp();
					$('#company_match_amount').text(data.amount);
					$('#company_match_description').text(data.company_match_description);

					if (data.company_employee_identifier !== null) {
						$('#company_employee_identifier_value').text(data.company_employee_identifier);
						$('#company_employee_identifier_field').show();
					}
				} else {
					$('#has_company_match').slideUp();
					$('#no_company_match').slideDown();
				}
			}).always(function(){
				$('#company_match_loading').slideUp(100);
			})
		} else {
			$('#has_company_match').slideUp();
			$('#no_company_match').slideDown();
		}
	});

	// Add stretch pool

	$(".add_stretch_pool").on("change", function() {
		if (this.checked) {
			var path = $(this).data("path");
			$(".add_to_cart, #submit_donation_btn").fadeOut('fast');
			$(".add_stretch_pool_donation").fadeIn('fast').data("path", path);
		} else {
			$(".add_to_cart, #submit_donation_btn").fadeIn('fast');
			$(".add_stretch_pool_donation").fadeOut('fast').removeData("path");
		}
	});

	// Add to cart

	$('.add_to_cart').on("click", function(e) {
		e.preventDefault();
		$('#form_billing_section').find('input, select').removeAttr('required');
		var action = '/add_to_cart?continue=true';

		if ( $(this).data("path") !== undefined ) {
			action += "&path=" + encodeURIComponent( $(this).data("path") );
		}
		$('#new_donation').attr('action', action);
		$('#submit_donation_btn').trigger('click');
		return false;
	});

	// New donation

	$('#new_donation, #cart_checkout').on('ajax:before', function(e) {
		$('#form_error').slideUp();
		$('#transaction_loading').slideDown('fast');

		if (window.donation_not_required != true) {
			var val = $('#amount_input').val();

			if (val < 5) {
				e.preventDefault();
				$('#choose_donation_amount').show();
				$('#transaction_loading').slideUp('fast');
				var position = $('#choose_donation_amount').offset().top;
				$('html, body').animate({scrollTop: position}, 500, function() {
					$('#submit_donation_btn').prop('disabled', false);
					$('#checkout_btn').prop('disabled', false);
				});
			}
		}
	}).on('ajax:success', function(event) {
		var detail = event.detail;
		var data = detail[0], status = detail[1], xhr = detail[2];

		if (data.cart_saved && data.cart_saved == true) {
			if (data.continue && data.continue == true) {
				location.href = decodeURIComponent(data.path);
			} else {
				location.href = '/cart';
			}
			return;
		}

		var sessionID = data.session_id;
    if(sessionID == undefined && window.donation_not_required){
      console.log("sessionID is undefined and donation_not_required")
      return;
    }

		stripe.redirectToCheckout({
			sessionId: sessionID
		}).then(function (result) {
			$('#form_error_text').text(result.error.message);
			$('#form_error').slideDown();
		});
	}).on('ajax:error', function(event) {
		var data = event.detail[0];
		var alert = data.alert;
		if (alert !== undefined && alert.length > 0) {
			$('#form_error_text').text(alert);
		}
		$('#form_error').slideDown();
		$('#transaction_loading').slideUp('fast');
	});
};